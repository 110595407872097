import React, { useState, useRef } from 'react'
import Slider from 'react-slick'

export const PersonalSlider = ({photos_slider_}) => {
    const refSlider = useRef(null)
    const nextSlide = () => {
        document.getElementById('nextBtnSlider').classList.add('activeBtn')
        refSlider.current.slickNext()
        setTimeout(() => {
            document
                .getElementById('nextBtnSlider')
                .classList.remove('activeBtn')
        }, 500)
    }
    const prevSlide = () => {
        document.getElementById('prevBtnSlider').classList.add('activeBtn')
        refSlider.current.slickPrev()
        setTimeout(() => {
            document
                .getElementById('prevBtnSlider')
                .classList.remove('activeBtn')
        }, 500)
    }
    const settings = {
        infinite: true,
        speed: 500,
        swipeToSlide: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        variableWidth: true,
        rtl: true,
    }
    const slidesArray = photos_slider_;
    const [trackSlider, setTrackSlider] = useState('')

    const onMouseEnter = () => {
        setTrackSlider('sliderTrackMoved')
    }
    const onMouseLeave = () => {
        setTrackSlider('')
    }
    return (
        <div id="personalSlider" className={`${trackSlider} personalSlider`}>
            <Slider
                ref={refSlider}
                className={`personal-slider`}
                {...settings}
            >
                {photos_slider_.map((item,indx) => (
                    <div
                        onMouseEnter={() => onMouseEnter()}
                        onMouseLeave={() => onMouseLeave()}
                        key={indx}
                        className='slideFlex'
                        >
                            <div style={{backgroundImage: `url(${item.image})`, width: '20vw'}}></div>
                    </div>
                ))}
            </Slider>
            <button
                id="prevBtnSlider"
                className="prev-slider"
                onClick={() => prevSlide()}
            />
            <button
                id="nextBtnSlider"
                className="next-slider"
                onClick={() => nextSlide()}
            />
        </div>
    )
}
