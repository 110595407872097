import {axiosAppClient} from '../utils/axiosAppClient'
import {useQuery} from "react-query";

function getSiteSetting(key) {
    let url = '/api/v1/site-setting/'
    if (key){
        url += `${key}/`
    }

    return axiosAppClient.get(url)
}

export function useSiteSetting(key) {
    let cacheKey = 'site-setting'
    if(key){
        cacheKey = `site-setting:${key}`
    }

    return useQuery(cacheKey, () => getSiteSetting(key))
}
