import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import StoryFooter from '../Pages/StoryPage/StoryComponents/StoryBottom/StoryFooter'
import {useCaseData} from "../../api";
import CursorProvider from '../CursorProvider/CursorProvider'

const CasesFullMobile = () => {
    const [isClicked, setIsClicked] = useState(false)
    const {isLoading: isCaseLoading, isSuccess: isCaseSuccess, data: caseData} = useCaseData()
    return (
        <CursorProvider>
        <div className="story-mobile">
            <div className="mobile-header">
                <Link to="/story" className="link">
                    Our story
                </Link>
                <Link to="/" className="to-home"></Link>
                <Link to="/hire" className="link">
                    hire us
                </Link>
            </div>           
            <div className="cases-full-array">
                {!isCaseLoading && isCaseSuccess && caseData != undefined && caseData.data.results.length > 0 && (<div>
                        {caseData.data.results.slice(0,6).map((item) => {
                            return (<Link to={'/cases/' + item.slug}>
                                <div style={{backgroundImage: item.preview_background_phone ? `url(${item.preview_background_phone})` : `url()`}}>
                                    <div style={{backgroundColor: 'rgba(0,0,0,0.25)' }} dangerouslySetInnerHTML={{__html: item.name_styled}}></div>
                                </div>
                            </Link>)
                            })
                        }
                        </div>)
                }
                <div
                    className={isClicked || (caseData == undefined || caseData.data == undefined || caseData.data.results == undefined || caseData.data.results.length < 7) ? 'displayNone' : 'LoadMore'}
                >
                    <a onClick={(e) => { e.preventDefault(); setIsClicked(!isClicked)}} href="">
                    <div>
                        <p>LOAD MORE</p>
                    </div>
                    </a>
                </div>
                {!isCaseLoading && isCaseSuccess && caseData != undefined && caseData.data.results.length > 6 && (<div>
                        {caseData.data.results.slice(6,caseData.data.results.length).map((item) => {
                            return (<Link to={'/cases/' + item.slug}>
                                <div className={isClicked ? '' : 'displayNone'} style={{backgroundImage: item.preview_background_phone ? `url(${item.preview_background_phone})` : `url()`}}>
                                    <div style={{backgroundColor: 'rgba(0,0,0,0.25)' }} dangerouslySetInnerHTML={{__html: item.name_styled}}></div>
                                </div>
                            </Link>)
                            })
                        }
                        </div>)
                }
            </div>
            <div className={isClicked ? 'mar70' : ''}>
                <Link to="/ourexpertise">
                    <div className="casesBtn gradientBtn mb02">
                        <p>
                            WE'RE<br />
                            READY<br />
                            HIRE US
                        </p>
                    </div>
                </Link>
            </div>
            <div>
                <StoryFooter current_page_={'cases'} />
            </div>
        </div>
        </CursorProvider>
    )
}

export default CasesFullMobile
