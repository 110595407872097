import {axiosAppClient} from '../utils/axiosAppClient'
import {useQuery} from "react-query";

function getContactManager() {
    return axiosAppClient.get('/api/v1/contact-manager/')
}

export function useContactManagerData() {
    return useQuery('contact-manager', getContactManager)
}
