import {axiosAppClient} from '../utils/axiosAppClient'
import {useQuery} from "react-query";

function getSocialProfile() {
    return axiosAppClient.get('/api/v1/social-profile/')
}

export function useSocialProfileData() {
    return useQuery('social-profile', getSocialProfile)
}
