import {axiosAppClient} from '../utils/axiosAppClient'
import {useQuery} from "react-query";

function getLogoCategory() {
    return axiosAppClient.get('/api/v1/logo-category/')
}

export function useLogoCategoryData() {
    return useQuery('logo-category', getLogoCategory)
}
