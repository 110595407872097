import React from 'react'
import ReactDOM from 'react-dom'
import './font/stylesheet.css'
import RouterComponent from './RouterComponent'
import {BrowserRouter} from 'react-router-dom'
import {
    QueryClient,
    QueryClientProvider,
} from 'react-query'

const queryClient = new QueryClient()

ReactDOM.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <RouterComponent/>
            </BrowserRouter>
        </QueryClientProvider>
    </React.StrictMode>,

    document.getElementById('root')
)
