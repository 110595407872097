import React from 'react'
import './CasesPagePersonal.css'
import { Link } from 'react-router-dom'

const ContactBlock = ({casemembersrow_set_}) => {
    return (
        <div className="contact-block">
            <span className="shape pos6" />
            <Link to='/hire' >
                    <div className="pStoryBtn gradientBtn contactBTNMY">
                        <p>
                            We’re
                            <br />
                            ready
                            <br />
                            Hire us
                        </p>
                    </div>
            </Link>
            {casemembersrow_set_ && (
                <>
                    {casemembersrow_set_.map(item => {  
                            return ( <div className="line-block"> { item.casemember_set.map(item_ => {
                                return (  
                                    <div>
                                        <p className={item_.name_size == 'big' ? 'line-block-contact-big contact' : 'line-block-contact-default contact'}>{item_.name}</p>
                                        <p className={item_.post_size == 'big' ? 'line-block-position-big position' : 'line-block-position-default position'}>{item_.post}</p>
                                    </div>
                                )
                           })}</div>)
                    })}
                </>
            )}

        </div>
    )
}

export default ContactBlock
