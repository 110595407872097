import React from 'react'

const TextBlock = ({ text_left, text_right, heightClass}) => {
    return (
        <div className={`${heightClass} text-block`}>
            <div className="text-block-div">
                <div className="text-block-p" dangerouslySetInnerHTML={{__html: text_left}}></div>
                <div className="text-block-p approach" dangerouslySetInnerHTML={{__html: text_right}} ></div>
            </div>
        </div>
    )
}

export default TextBlock
