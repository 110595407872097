import React from 'react'
import './Cases.css'
import SwiperSLider from '../MainPage/Swiper/SwiperSLider'
import { Link } from 'react-router-dom'
import {useCaseData} from "../../api";
import CursorProvider from '../CursorProvider/CursorProvider'

const CasesMobile = () => {
    const {isLoading: isCaseLoading, isSuccess: isCaseSuccess, data: caseData} = useCaseData()
    return (
        <CursorProvider>
        <div>
            <Link to="/allcases">
                <div className="caseBtnSlider">
                    <p>
                        View
                        <br />
                        cases
                        <br />
                        list
                    </p>
                </div>
            </Link>
            <SwiperSLider casesArrayLenght={!isCaseLoading && isCaseSuccess ? caseData.data.results.length : 0} casesArray={!isCaseLoading && isCaseSuccess ? caseData.data.results : []} />
            <div className="main__top">
                <Link to="/">
                    <div className="main__logo" />
                </Link>
            </div>
        </div>
        </CursorProvider>
    )
}

export default CasesMobile
