import React from 'react'
import { Link } from 'react-router-dom'
import './StoryBottom.css'
import Pdf from '../../../../PrivacyPolicy.pdf';

const StoryFooter = ({mForFooter,current_page_}) => {
    return (
        <div className={`story-footer ${mForFooter} `}>
            <div className="story-footer-left story-footer-left-desk">
                <Link className={current_page_ == "home" ? 'active' : ''} to='/'>Home</Link>
                <Link className={current_page_ == "cases" ? 'active' : ''} to='/cases'>Cases</Link>
                <Link className={current_page_ == "story" ? 'active' : ''} to='/story'>Our story</Link>
                <Link className={current_page_ == "hire" ? 'active' : ''} to='/hire'>Hire us</Link>
            </div>
            <div className="story-footer-left story-footer-left-mobile">
                <Link className={current_page_ == "home" ? 'active' : ''} to='/'>Home</Link>
                <Link className={current_page_ == "cases" ? 'active' : ''} to='/cases'>Cases</Link>
                <Link className={current_page_ == "story" ? 'active' : ''} to='/story'>Our story</Link>
                <Link className={current_page_ == "hire" ? 'active' : ''} to='/hire'>Hire us</Link>
            </div>
            <Link to='/'>
                <div className="story-footer-middle" />
            </Link>
            <div className="story-footer-right">
                <div>
                    <p>Wearefx © 2021 by </p>
                    <p className="violet_on_hover">
                        <a
                            className="violet_on_hover"
                            target="_blank"
                            href="https://brah.design/"
                        >
                            {' '}
                            Brāh lab ﹤
                        </a>
                    </p>
                </div>
                <p className="violet_on_hover">
                    <a className="violet_on_hover" target = "_blank"
                        href={Pdf}>
                        Privacy Policy
                    </a>
                </p>
                
            </div>
        </div>
    )
}

export default StoryFooter
