import React, { useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import StoryFooter from '../Pages/StoryPage/StoryComponents/StoryBottom/StoryFooter'
import Slider from 'react-slick'
import { PersonalSliderMobile } from '../Pages/CasesPagePersonal/PersonalSliderMobile'
import TextBlock from '../Pages/CasesPagePersonal/TextBlock'
import ContactBlock from '../Pages/CasesPagePersonal/ContactBlock'
import slide_ from '../../images/personal/gif.png';
import slide_log_1 from '../../images/personal/NAVI.png';
import slide_log_2 from '../../images/personal/Puma.png';
import slide_2 from '../../images/personal/gif2.png';
import next_proj from '../../images/gifcase2.svg';
import ReactPlayer from 'react-player'
import VideoFullControls from '../Pages/CasesPagePersonal/VideoFullControls/VideoFullControls'
import {useParams} from "react-router-dom";
import {useCaseData} from "../../api";
import CursorProvider from '../CursorProvider/CursorProvider'

const CasesPersonalMobile = () => {
    const { id } = useParams();
    const {isLoading: isCaseLoading, isSuccess: isCaseSuccess, data: caseData} = useCaseData(id)
    const [isClicked, setIsClicked] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const settingsSliderVideos = {
        infinite: true,
        speed: 500,
        swipeToSlide: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (current, next) => {
            setCurrentSlide(next + 1);
        }
    }
    const settingsSliderVideos2 = {
        infinite: true,
        speed: 500,
        swipeToSlide: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (current, next) => {
            setCurrentSlide2(next + 1);
        }
    }
    const [videoState, setVideoState] = useState({
        playing: false,
        muted: false,
        video: '',
        loadedSeconds: 1,
        playedSeconds: 0,
    })
    const [fullscreen, setFullscreen] = useState(false)
    const refVideo = useRef(null)
    const [CurrentSlide, setCurrentSlide] = useState(1);
    const [CurrentSlide2, setCurrentSlide2] = useState(1);
    const [AllSlides, setAllSlides] = useState(3);
    const { playing, muted, video, loadedSeconds, playedSeconds } = videoState
    const handlePlay = () => {
        setVideoState({ ...videoState, playing: !videoState.playing })
    }
    const handleMuted = () => {
        setVideoState({ ...videoState, muted: !videoState.muted })
    }
    const handleProgress = (e) => {
        setVideoState({ ...videoState, ...e })
    }
    const handleEnded = (e) => {
        setVideoState({ ...videoState, playing: !videoState.playing })
    }
    const handleProgressTrack = (e) => {
        var stream = document.querySelectorAll('.full_video_modal_content_video video');
        stream = stream && stream[0] ? stream[0] : null; 
        if(stream == null) return; 
        stream.currentTime  = Number(e);
        //refPlayer.current.seekTo(Number(e))
    }
    const handleFullScreen = () => {
        var doc_ = document.getElementsByClassName('full_video_modal_content_video')[0] ? document.getElementsByClassName('full_video_modal_content_video')[0] : null;
        var doc_controls = document.getElementsByClassName('video-controls')[0] ? document.getElementsByClassName('video-controls')[0] : null;
        if (!fullscreen) {
            var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
            if (doc_) {
                var px = window.innerWidth / 2;
                var py = (window.innerHeight / 2) + (isIOS ? 28 : 0);
                var py2 = (window.innerHeight / 2);
                doc_.style = "width: " + (window.innerHeight + (isIOS ? 56 : 0)) + "px; height: " + (window.innerWidth) + "px;" +
                "left: " + (px-py) + "px;top: " + (py-px) + "px;";
                doc_controls.style = "width: " + window.innerHeight + "px; height: " + window.innerWidth + "px;" +
                "left: " + (px-py2) + "px;top: " + (py2-px) + "px;";
            }
            setFullscreen(true)
        } else {
            if (doc_) {
                doc_.style = "";
                doc_controls.style = "";
            }
            setFullscreen(false)
        }
    }
    const handleFullScreenClose = () => {
        setFullscreen(false)

    }
    const ModalProcess = (video_) => {
        setVideoState({ ...videoState, video: video_.video })
        if (showModal) {
            setVideoState({ ...videoState, playing: false });
            refVideo.current.seekTo(0);
        }
        setShowModal(!showModal)
    }
    return (
        <CursorProvider>
        <div className="story-mobile">
            <div className={showModal ? 'full_video_modal show_modal' : 'full_video_modal'}>
                <div className='full_video_modal_wrapper'>
                    <div className='full_video_modal_noise'></div>
                    <div className='full_video_modal_x'></div>
                    <div className={fullscreen ? 'full_video_modal_logo displayNoneMain' : 'full_video_modal_logo'}>
                        <Link to="/" className="to-home"></Link>
                    </div>
                    <div style={{top: '22%'}} onClick={ModalProcess} className={fullscreen ? 'backHref displayNoneMain' : 'backHref'}>&#60; Back to case</div>
                    <div className='full_video_modal_content'>
                        <div className={fullscreen ? 'full_video_modal_content_video go_to_full' : 'full_video_modal_content_video'}>
                            <ReactPlayer
                                width={'100%'}
                                playsinline={true}
                                height={'42vh'}
                                url={video}
                                muted={muted}
                                playing={playing}
                                ref={refVideo}
                                onProgress={handleProgress}
                                onEnded={handleEnded}
                            />
                        </div>
                    </div>
                    <VideoFullControls
                        playing={playing}
                        handlePlay={handlePlay}
                        muted={muted}
                        fullscreen={fullscreen}
                        handleMuted={handleMuted}
                        loadedSeconds={loadedSeconds}
                        playedSeconds={playedSeconds}
                        handleProgressTrack={handleProgressTrack}
                        handleFullScreen={handleFullScreen}
                        handleFullScreenClose={handleFullScreenClose}
                    />
                </div>
            </div>
            <div className='nose_'>
                <div className="mobile-header">
                    <Link to="/story" className="link">
                        Our story
                    </Link>
                    <Link to="/" className="to-home"></Link>
                    <Link to="/hire" className="link">
                        hire us
                    </Link>
                </div>
                <Link to="/cases" className="backHref">
                    &#60; Back to cases
                </Link>
                <div className='cpm_'>
                    {caseData && <div className="header-title" dangerouslySetInnerHTML={{__html: caseData.data.name_styled}}></div>}
                    {caseData && <h6 className="header-desc">{caseData.data.description}</h6>}
                </div>
                {caseData && caseData.data.caseprimaryvideo_set && (
                    <div className='sliderWrapper'>
                        <Slider {...settingsSliderVideos}>
                            {caseData.data.caseprimaryvideo_set.map(i => {
                                return (
                                    <div className='sl-itm'>
                                        <img src={i.preview} alt="" />
                                        <div onClick={() => { ModalProcess(i);}} className='btn_play_video'>Play full video</div>
                                    </div>
                                )
                            })}
                        </Slider>
                        {caseData.data.caseprimaryvideo_set.length > 1 && (
                        <>
                            <div className='paginationSliders'>
                                <span className='paginationSliders-current'>{CurrentSlide}</span>/{(caseData && caseData.data.caseprimaryvideo_set ? caseData.data.caseprimaryvideo_set.length : 0)}
                            </div>
                        </>
                        )}
                    </div>
                )}
                {caseData && caseData.data.first_section_label && caseData.data.first_section_text && (
                <TextBlock
                    heightClass={'heightClass3'}
                    key={caseData.data.first_section_label}
                    text_left={
                        caseData.data.first_section_text
                    }
                    text_right={
                        caseData.data.first_section_label
                    }
                />)}
                {caseData && caseData.data.casesecondaryvideo_set && (
                    <div className='sliderWrapper'>
                        <Slider {...settingsSliderVideos2}>
                            {caseData.data.casesecondaryvideo_set.map(i => {
                                return (
                                    <div className='sl-itm'>
                                        <img src={i.preview} alt="" />
                                        <div onClick={() => { ModalProcess(i);}} className='btn_play_video'>Play full video</div>
                                    </div>
                                )
                            })}
                        </Slider>
                        {caseData.data.casesecondaryvideo_set.length > 1 && (
                        <>
                            <div className='paginationSliders'>
                                <span className='paginationSliders-current'>{CurrentSlide2}</span>/{(caseData && caseData.data.casesecondaryvideo_set ? caseData.data.casesecondaryvideo_set.length : 0)}
                            </div>
                        </>
                        )}
                    </div>
                )}
                {caseData && caseData.data.secondary_section_label && caseData.data.secondary_section_text && (
                <TextBlock
                    heightClass={'heightClass'}
                    key={caseData.data.secondary_section_label}
                    text_left={
                        caseData.data.secondary_section_text
                    }
                    text_right={
                        caseData.data.secondary_section_label
                    }
                />)}
                {caseData && caseData.data.casesliderimage_set && caseData.data.casesliderimage_set.length && caseData.data.casesliderimage_set.length > 0 && (
                <PersonalSliderMobile photos_slider_={caseData != undefined && caseData.data != undefined && caseData.data.casesliderimage_set != undefined ? caseData.data.casesliderimage_set : []} />
                )}
                {caseData && caseData.data.third_section_label && caseData.data.third_section_text && (
                <TextBlock
                    key={caseData.data.third_section_label}
                    heightClass={'heightClass2 text-block-special-bg'}
                    text_left={
                        caseData.data.third_section_text
                    }
                    text_right={
                        caseData.data.third_section_label
                    }
                />)}
                <div>
                    {caseData && caseData.data.casegalleryimage_set && caseData.data.casegalleryimage_set.length && (
                        <>
                            {caseData.data.casegalleryimage_set.map(i => {
                                return (
                                    <div className='PreviewImages'>
                                        <img src={i.image} alt={i.description} />
                                    </div>
                                )
                            })}
                        </>
                    )}
                </div>
                <div className='cpm_button_position'>
                    <Link to="/ourexpertise">
                        <div className="casesBtn gradientBtn hmb02 hmb03">
                            <p>
                                View our
                                <br />
                                expertise &<br />
                                tech.
                            </p>
                        </div>
                    </Link>
                </div>
                <Link to="/hire">
                    <div className="violetBtn">
                        <p>
                            we’re ready
                            <br />
                            hire us!
                        </p>
                    </div>
                </Link>
                {caseData && caseData.data.casemembersrow_set && caseData.data.casemembersrow_set.length && caseData.data.casemembersrow_set.length > 0 && (
                    <ContactBlock casemembersrow_set_={caseData != undefined && caseData.data != undefined && caseData.data.casemembersrow_set != undefined ? caseData.data.casemembersrow_set : []} />
                )}
                <Link to={'/cases/' + (caseData ? caseData.data.next_case.slug : '')}>
                    <div className='cpm_next_project'>next project</div>
                </Link>
                <Link to={'/cases/' + (caseData ? caseData.data.next_case.slug : '')}>
                <div className='cpm_next_project_block'>
                    <div className='cpm_next_project_block_overlay'></div>
                    <div className='cpm_next_project_block_text'>
                        <article dangerouslySetInnerHTML={{__html: (caseData ? caseData.data.next_case.name_styled : '')}}></article>
                        <p>{(caseData ? caseData.data.next_case.description : '')}</p>
                    </div>
                    <img src={(caseData ? caseData.data.next_case.preview_background_phone : '')} alt="" />
                </div>
                </Link>
                <div>
                    <StoryFooter current_page_={'cases'} />
                </div>
            </div>
        </div>
        </CursorProvider>
    )
}

export default CasesPersonalMobile
