import {axiosAppClient} from '../utils/axiosAppClient'
import {useQuery} from "react-query";

function getCase(key) {
    let url = '/api/v1/case/'
    if (key){
        url += `${key}/`
    }

    return axiosAppClient.get(url)
}

export function useCaseData(key) {
    let cacheKey = 'case'
    if(key){
        cacheKey = `case:${key}`
    }

    return useQuery(cacheKey, () => getCase(key))
}
