import {axiosAppClient} from '../utils/axiosAppClient'
import {useQuery} from "react-query";

function getSiteVideo(key) {
    let url = '/api/v1/site-video/'
    if (key){
        url += `${key}/`
    }

    return axiosAppClient.get(url)
}

export function useSiteVideoData(key) {
    let cacheKey = 'site-video'
    if(key){
        cacheKey = `site-video:${key}`
    }

    return useQuery(cacheKey, () => getSiteVideo(key))
}
