import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Cube from '../../Cube/Cube'
import CursorProvider from '../../CursorProvider/CursorProvider'
import './HirePage.css'
import '../StoryPage/StoryComponents/StoryHeader/StoryHeader.css'
import HirePageHeader from './HirePageHeader'
import Pdf from '../../PrivacyPolicy.pdf';
import {useContactManagerData, useSocialProfileData, useSiteSetting} from "../../../api";

const HirePage = () => {
    useEffect(() => {
        document.getElementById('polyline').classList.add('displayNone')
        document.getElementById('polyline1').classList.add('displayNone')
        document.getElementById('polyline2').classList.add('displayNone')
        document.getElementById('overlay1').classList.add('displayNone')
        document.getElementById('overlay2').classList.add('displayNone')
        document.getElementById('overlay3').classList.add('displayNone')
    }, [])

    const { isLoading: isSocialProfileDataLoading, isSuccess: isSocialProfileDataSuccess, data: socialProfileData } = useSocialProfileData()
    const { isLoading: isContactManagerDataLoading, isSuccess: isContactManagerDataSuccess, data: contactManagerData } = useContactManagerData()
    const { isLoading: isMainEmailDataLoading, isSuccess: isMainEmailDataSuccess, data: mainEmailData } = useSiteSetting('main_email')
    
    return (
        <CursorProvider>
            <div className='hirefullPage'>
                <Cube />
                <HirePageHeader />
                <div className="hireBLock deb hireBLockFull">
                    <div className="hireBLock__left">
                        { !isSocialProfileDataLoading && isSocialProfileDataSuccess && (
                            <div className="hireBLock__left__top">
                                {socialProfileData.data.results.slice(0, 4).map(i => {
                                    return <a key={i.name} target="_blank" className='hover__special__menu' href={i.link}>{i.name}</a>
                                })}
                            </div>
                        )}
                        <div className="hireBLock__left__middle">
                            <p>
                                All contents of this website are the property<br/>oF
                                wearefx STUDIO. No part of this site,<br/>including
                                all text and images, may be<br/>reproduced in any
                                form without the prior<br/>written consent oF
                                wearefx STUDIO ©2021
                            </p>
                            <div className="arrowToTop" />
                        </div>
                        <h3 className="hireBLock__left__bottom">
                            We’re curious how<br />we can
                            help<span className='span__special__color'>,</span><br />get in touch 👋
                        </h3>
                    </div>
                    <div className="hireBLock__right">
                        <div className="hireBLock__right__top">
                            <p>
                                Wearefx © 2022 by
                                <a className="violet_on_hover" target="_blank"
                                href="https://brah.design/">
                                    {' '}
                                    Brāh lab
                                </a>
                            </p>
                            <a className="violet_on_hover" target = "_blank"
                            href={Pdf}>
                                Privacy Policy
                            </a>
                        </div>
                        <div className="hireBLock__right__middle">
                            <p>For general inquiries<br /> & new projects </p>
                            {!isMainEmailDataLoading && isMainEmailDataSuccess && (
                                <h3><a href={`mailto:${mainEmailData.data.value}`}>{mainEmailData.data.value}</a></h3>
                            )}
                        </div>
                        <div className="hireBLock__right__bottom">
                             { !isContactManagerDataLoading && isContactManagerDataSuccess && (
                                <div className='hbr_col'>
                                    {contactManagerData.data.results.slice(0, 3).map(i => {
                                        return (
                                            <div key={i.name} className="contact contactss">
                                                {i.post && <p>{i.post}</p>}
                                                {i.name && <p>{i.name}</p>}

                                                {(i.email || i.phone) && (
                                                    <div className="contact__tel">
                                                        {i.email && <p><a href={`mailto:${i.email}`}>{i.email}</a></p>}
                                                        {i.phone && <a href={`tel:${i.phone}`} target='_blank'>{i.phone}</a>}
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    })}
                                </div>
                            )}
                            { !isContactManagerDataLoading && isContactManagerDataSuccess && (
                                <div className='hbr_col'>
                                    {contactManagerData.data.results.slice(3, 6).map(i => {
                                        return (
                                            <div key={i.name} className="contact contactss">
                                                {i.post && <p>{i.post}</p>}
                                                {i.name && <p>{i.name}</p>}

                                                {(i.email || i.phone) && (
                                                    <div className="contact__tel">
                                                        {i.email && <p><a href={`mailto:${i.email}`}>{i.email}</a></p>}
                                                        {i.phone && <a href={`tel:${i.phone}`} target='_blank'>{i.phone}</a>}
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </CursorProvider>
    )
}

export default HirePage
