import React from 'react'
import { Link } from 'react-router-dom'
import './SwiperItem.css'

const SwiperItem = ({ linkTo, title, paragraph, current, bg_img_, count }) => {
    return (
        <div className="swiper-item" style={{backgroundImage: bg_img_ ? `url(${bg_img_})` : `url()`}}>
            <div className='cases-mobile-bg'>
                <div className='cases-mobile-bg-overlay-sh'>
                    <div className='cases-mobile-bg-overlay'></div>
                </div>
                <div className='slide-content'>
                    <div>
                        <article dangerouslySetInnerHTML={{__html: title }}></article>
                        <p>{paragraph}</p>
                    </div>
                    {count > 1 && (
                    <>
                        <div>
                            <span>{current}</span>/{ count }
                        </div>
                    </>
                    )}
                </div>
                <div className="caseLinkIMP">
                    <Link to={linkTo}>
                        <div className="casesBtn gradientBtn btm0">
                            <span className="swiper-item-p">
                                Explore
                                <br />
                                case
                                <br />
                                details
                            </span>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default SwiperItem
