import React, { Component } from 'react'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import SwiperItem from './SwiperItem';

export default class SimpleSlider extends Component {
    render() {
        const settings = {
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        }
        const casesArray = this.props.casesArray;
        const casesArrayLenght = this.props.casesArrayLenght;
        return (
            <div id='cases-slider-wraper'>
                <Slider {...settings}>
                    {casesArray.map((i,indx) => {
                        return (
                            <SwiperItem bg_img_={i.preview_background_phone} linkTo={`/cases/${i.slug}`} title={i.name_styled} paragraph={i.description} current={indx+1} count={casesArrayLenght}/>
                        )
                    })}
                </Slider>
            </div>
        )
    }
}
