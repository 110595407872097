import React from 'react'
import { Link } from 'react-router-dom'
import StoryFooter from '../Pages/StoryPage/StoryComponents/StoryBottom/StoryFooter'
import './HireMobile.css'
import Pdf from '../PrivacyPolicy.pdf';
import {useContactManagerData, useSocialProfileData, useSiteSetting} from "../../api";
import CursorProvider from '../CursorProvider/CursorProvider'

const HireMobile = () => {
    const { isLoading: isSocialProfileDataLoading, isSuccess: isSocialProfileDataSuccess, data: socialProfileData } = useSocialProfileData()
    const { isLoading: isContactManagerDataLoading, isSuccess: isContactManagerDataSuccess, data: contactManagerData } = useContactManagerData()
    const { isLoading: isMainEmailDataLoading, isSuccess: isMainEmailDataSuccess, data: mainEmailData } = useSiteSetting('main_email')
    return (
        <CursorProvider>
        <div className="story-mobile">
            <div className="mobile-header">
                <Link to="/cases" className="link">
                    Cases
                </Link>
                <Link to="/" className="to-home"></Link>
                <Link to="/story" className="link">
                    Our story
                </Link>
            </div>
            <div className="story-full-text">
                We’re curious how we can help<span className='schar__'>,</span> get in touch 👋
            </div>
            <div className="story-p-text">
                For general inquiries & new projects
            </div>
            {!isMainEmailDataLoading && isMainEmailDataSuccess && (
                <div className="story-full-text norm"><a className='norm' href={`mailto:${mainEmailData.data.value}`}>{mainEmailData.data.value}</a></div>
            )}

            { !isContactManagerDataLoading && isContactManagerDataSuccess && (
                <div>
                    {contactManagerData.data.results.map(i => {
                        return (
                            <div key={i.name} className="story-contact">
                                {i.post && <p>{i.post}</p>}
                                {i.name && <p>{i.name}</p>}
                                {(i.email || i.phone) && (
                                    <div>
                                        <div className="story-full-text-mail"><a href={`mailto:${i.email}`}>{i.email}</a></div>
                                        <div className="story-full-text-phone"><a href={`tel:${i.phone}`} target='_blank'>{i.phone}</a></div>
                                    </div>
                                )} 
                            </div>  
                        )
                    })}
                </div>
            )}
            

            { !isSocialProfileDataLoading && isSocialProfileDataSuccess && (
                <div className="hire-social">
                    {socialProfileData.data.results.map(i => {
                        return <a key={i.name} style={{backgroundImage: `url(${i.icon})`}} target="_blank" href={i.link}></a>
                    })}
                </div>
            )}
            <div className="story-mobile-text">
                <div>
                    <p className="story-mobile-text2">
                        All contents of this website are the property oF wearefx
                        STUDIO. No part of this site, including all text and
                        images, may be reproduced in any form without the prior
                        written consent oF wearefx STUDIO ©2021
                    </p>
                    <div className="arrow-to-left" />
                </div>
            </div>
            <div className="hire-footer-right">
                <div>
                    <p>Wearefx © 2021 by </p>
                    <p className="violet_on_hover fw_norm">
                        <a
                            className="violet_on_hover"
                            target="_blank"
                            href="https://brah.design/"
                        >
                            {' '}
                            Brāh lab ﹤
                        </a>
                    </p>
                </div>
                <p className="violet_on_hover fw_norm">
                    <a className="violet_on_hover" target = "_blank"
                        href={Pdf}>
                        Privacy Policy
                    </a>
                </p>
            </div>
            <Link to="/cases">
                <div className="violetBtn">
                    <p>
                        View our
                        <br />
                        cases
                    </p>
                </div>
            </Link>

            <div>
                <StoryFooter current_page_={'hire'} />
            </div>
        </div>
        </CursorProvider>
    )
}

export default HireMobile
