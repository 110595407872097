import React, { useEffect, useState } from 'react'
import Cube from '../../Cube/Cube'
import './CasesPage.css'
import '../StoryPage/StoryComponents/StoryHeader/StoryHeader.css'
import CursorProvider from '../../CursorProvider/CursorProvider'
import { CasesSlider } from './CasesSlider/CasesSlider'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import Tilt from 'react-parallax-tilt'
import {useCaseData} from "../../../api";

export const CasesPage = () => {
    const {isLoading: isCaseLoading, isSuccess: isCaseSuccess, data: caseData} = useCaseData()
    const [linkToVideo, setLinkToVideo] = useState(null)
    const [pageCountCases, setPageCountCases] = useState()
    const [currentPage, setCurrentPage] = useState(1)
    const [isHoveredBackground, setIsHoveredBackground] = useState('')
    const [showedCases, setShowedCases] = useState([])

    const [showCube, setShowCube] = useState('')

    const [inCase, setInCase] = useState(false)

    const [CasesHovered, setCasesHovered] = useState(false)
    const [CasesTitle, setCasesTitle] = useState('')
    const [CasesDescription, setCasesDescription] = useState('')

    const navigate = useNavigate()

    const onMouseEnterCase = (e, item) => {
        for (let index = 0; index < showedCases.length; index++) {
            showedCases[index].isHovered = false;
        }
        item.isHovered = false;
        setCasesTitle('');
        setCasesDescription('');
        setCasesHovered(false);
        sethovered(null)
        setIsHoveredBackground('')
        try {
            var stream = document.getElementById('MainVideo_');
            stream = stream ? stream : null; 
            if(!stream) return;
            stream.pause();
            stream.currentTime = 0;
        } catch (error) {
            
        }
        item.isHovered = true;
        setLinkToVideo(item.preview_background);
        setCasesTitle(item.name_styled);
        setCasesDescription(item.description);
        setCasesHovered(true);
        sethovered([e.clientX, e.clientY]);
        setIsHoveredBackground(item.casesArrayItemBack);
        try {
            var stream = document.getElementById('MainVideo_');
            stream = stream ? stream : null; 
            if(!stream) return;
            if (stream.paused) {
                try {
                    stream.load();
                } catch (error) {  }
            }
            stream.play();
        } catch (error) {
            
        }
        
    }
    const onMouseLeaveCase = (e, item) => {
        for (let index = 0; index < showedCases.length; index++) {
            showedCases[index].isHovered = false;
        }
        item.isHovered = false;
        setCasesTitle('');
        setCasesDescription('');
        setCasesHovered(false);
        sethovered(null)
        setIsHoveredBackground('')
        try {
            var stream = document.getElementById('MainVideo_');
            stream = stream ? stream : null; 
            if(!stream) return;
            stream.pause();
            stream.currentTime = 0;
        } catch (error) {
            
        }
        
    }
    const onLoyMove = (e) => {
        if(inCase) {
            setInCase(false);
            return;
        }
        for (let index = 0; index < showedCases.length; index++) {
            showedCases[index].isHovered = false;
        }
        setInCase(false);
        setCasesTitle('');
        setCasesDescription('');
        setCasesHovered(false);
        sethovered(null)
        setIsHoveredBackground('')
        var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        if (!isSafari) {
            document.getElementById(
                'parallaxImageBlock'
            ).style.cssText = ``
        } else {
            document.getElementById(
                'parallaxImageBlock'
            ).style.cssText = `transform: none !important;width: 100vw !important; left: 0 !important;`;
            document.getElementById(
                'MainVideo_'
            ).style.cssText = `left: -7.5vw !important;`;
        }
    }
    

    useEffect(() => {
        document.getElementById('polyline').classList.add('displayNone')
        document.getElementById('polyline1').classList.add('displayNone')
        document.getElementById('polyline2').classList.add('displayNone')
        document.getElementById('overlay1').classList.add('displayNone')
        document.getElementById('overlay2').classList.add('displayNone')
        document.getElementById('overlay3').classList.add('displayNone')
    }, [])


    useEffect(() => {
        if(isCaseSuccess && caseData && caseData.data && caseData.data.results) {
            var mid_arr_ = caseData.data.results;
            var start_anim_del = 0;
            var current_anim_del = 0.2;
            var page_count = Math.ceil(mid_arr_.length / 12);
            var calc_start_position = 0;
            for (let a = 1; a <= page_count; a++) {
                for (let b = calc_start_position; b < a * 12; b++) {
                    if(b > mid_arr_.length - 1) continue;
                    start_anim_del += 0.12;
                    mid_arr_[b]['delay'] = start_anim_del + 's';
                    mid_arr_[b]['casesArrayItemBack'] = 'mcHoveredBack';
                    mid_arr_[b]['personalClass'] = 'hush-MC';
                    mid_arr_[b]['page'] = a;
                    mid_arr_[b]['isHovered'] = false;
                } 
                calc_start_position = (a * 12);
                start_anim_del = 0;
            }
            setShowedCases(mid_arr_);
        }
    }, [isCaseSuccess])


    useEffect(() => {
        if (isHoveredBackground) setShowCube('')
        else setShowCube('')
    }, [isHoveredBackground])

    const [hovered, sethovered] = useState(null)

    const parallaxHandler = (e) => {
        setInCase(true);
        let x = 0,
            y = 0
        if (hovered === null) {
            // TODO: set previous code git
            x = 0
            y = 0
        } else {
            x = (e.clientX - hovered[0]) / 30
            y = (e.clientY - hovered[1]) / 30
        }
        /*document.getElementById(
            'MainVideo_'
        ).style.cssText = `transform: 
        rotateY(${y}deg) 
        rotateX(${x/1.3}deg) 
        scale3d(1,1,1)
        `*/
    }
    return (
        <CursorProvider>
            <div
            className=" animationFocus cases-page">
                <Cube showCube={`${showCube}`} />
                <div className="story-header">
                    <div className="header-left">
                        <div>
                            <h1>our</h1>
                            <h1>cases</h1>
                        </div>
                        <div>
                            <Link className='hover__special__menu' to="/story">our story</Link>
                            <Link className='hover__special__menu' to="/hire">hire us</Link>
                        </div>
                    </div>
                    <Link to="/">
                        <div className="animated-logo-X">X</div>
                        <div className="animated-logo header-right"></div>
                    </Link>
                </div>
                <div
                    onMouseMove={(e) => onLoyMove(e)}
                    onMouseEnter={(e) => onLoyMove(e)}
                    id="parallaxImageBlock"
                    className={`${isHoveredBackground} universalBack`}
                >
                    <div className={CasesHovered ? 'parallaxImageBlock_size parallaxImageBlock_shum' : ' displayNone'}></div>
                    <div className={CasesHovered ? 'parallaxImageBlock_size parallaxImageBlock_overlay' : ' displayNone'}></div>
                     <video playsInline loop muted className={CasesHovered ? '' : ' displayNone'} id='MainVideo_'>
                        <source src={linkToVideo} type="video/mp4"/>
                    </video>
                </div>
                <div className={CasesHovered ? 'casesMain' : ' displayNone'}>
                    <article dangerouslySetInnerHTML={{__html: CasesTitle}}></article>
                    <p>{CasesDescription}</p>
                </div>
                <div onMouseLeave={(e) => onLoyMove(e)} id='cases_arr_' className="cases-Array">
                    {!isCaseLoading && isCaseSuccess && ( <div>
                    {showedCases.filter((e) => e.page === currentPage).slice(0,6).map((item) => {
                        return (<div
                            onMouseMove={parallaxHandler}
                            style={{backgroundColor: item.isHovered ? item.name_background_color : ''}}
                            onMouseEnter={(e) => onMouseEnterCase(e, item)}
                            onMouseLeave={(e) => onMouseLeaveCase(e,item)}
                            className={
                                item.isHovered
                                    ? `${item.personalClass} casesArrayItem`
                                    : 'casesArrayItem'
                            }
                            key={item.slug}
                            onClick={() => navigate(`/cases/${item.slug}`)}
                        >
                            <article style={{animation: `lesenka ${item.delay} ease-in forwards`}}  dangerouslySetInnerHTML={{__html: item.isHovered ? item.name_styled_hover : item.name_styled}}></article >
                            <img className={item.isHovered ? 'cases_icon cases_icon_show' : 'cases_icon' }  src={item.name_logo} alt="" />
                        </div>)
                        })
                    }
                    </div>)}
                    {!isCaseLoading && isCaseSuccess && ( <div>
                    {showedCases.filter((e) => e.page === currentPage).slice(6,12).map((item) => {
                        return (<div
                            onMouseMove={parallaxHandler}
                            style={{backgroundColor: item.isHovered ? item.name_background_color : ''}}
                            onMouseEnter={(e) => onMouseEnterCase(e, item)}
                            onMouseLeave={(e) => onMouseLeaveCase(e,item)}
                            className={
                                item.isHovered
                                    ? `${item.personalClass} casesArrayItem`
                                    : 'casesArrayItem'
                            }
                            key={item.slug}
                            onClick={() => navigate(`/cases/${item.slug}`)}
                        >
                            <article style={{animation: `lesenka ${item.delay} ease-in forwards`}} dangerouslySetInnerHTML={{__html: item.isHovered ? item.name_styled_hover : item.name_styled}}></article >
                            <img className={item.isHovered ? 'cases_icon cases_icon_show' : 'cases_icon' } src={item.name_logo} alt="" />
                        </div>)
                        })
                    }
                    </div>)}
                </div>
                {showedCases && showedCases.length && showedCases.length > 12 && (
                    <CasesSlider state={currentPage} setState={setCurrentPage} count={!isCaseLoading && isCaseSuccess ? Math.ceil(showedCases.length/12) : 0} />
                )}

                <div className="casesTextBlock">
                    <div className="casesArrow" />
                    <p>
                        We’re always<br />looking the truth<br />about the brand we<br />work
                        with and tell<br />it naturaly and<br />gorgeous, like no<br />one else
                        before.
                    </p>
                </div>
                <span className="shape pos32" />
                <Link to="/hire">
                    <div className="casesBtn gradientBtn casesBtnPage">
                        <p>
                            We’re
                            <br />
                            ready
                            <br />
                            Hire us
                        </p>
                    </div>
                </Link>
            </div>
        </CursorProvider>
    )
}
