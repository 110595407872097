import React, { useEffect, useRef, useState } from 'react'
import CursorProvider from '../../CursorProvider/CursorProvider'
import StoryFooter from '../StoryPage/StoryComponents/StoryBottom/StoryFooter'
import './CasesPagePersonal.css'
import ContactBlock from './ContactBlock'
import { PersonalHeader } from './PersonalHeader'
import { PersonalSlider } from './PersonalSlider'
import TextBlock from './TextBlock'
import { Link } from 'react-router-dom'
import { VideoPersonal } from './Video/VideoPersonal'
import '../../../styles/Global.css'
import { VideoPersonal1 } from './Video2/VideoPersonal1'
import {useParams} from "react-router-dom";
import {useCaseData} from "../../../api";

const CasesPagePersonal = () => {
    const { id } = useParams();
    const {isLoading: isCaseLoading, isSuccess: isCaseSuccess, data: caseData} = useCaseData(id)

    useEffect(() => {
        document.getElementById('polyline').classList.add('displayNone')
        document.getElementById('polyline1').classList.add('displayNone')
        document.getElementById('polyline2').classList.add('displayNone')
        document.getElementById('overlay1').classList.add('displayNone')
        document.getElementById('overlay2').classList.add('displayNone')
        document.getElementById('overlay3').classList.add('displayNone')
    }, [])

    const refPlayer = useRef(null)
    const refPlayerScreenfull = useRef(null)

    const refPlayer1 = useRef(null)
    const refPlayerScreenfull1 = useRef(null)

    const [fullscreen, setFullscreen] = useState(false)

    const [fullscreen1, setFullscreen1] = useState(false)

    if(isCaseLoading){
        return 'Loading...'
    }

    if(!isCaseSuccess){
        return 'Error. Try later.'
    }

    return (
        <CursorProvider>
            <div id="personalPageId" className=" personal-page">
                <PersonalHeader
                    name={caseData.data.name_styled}
                    description={caseData.data.description}
                />
                <VideoPersonal
                    video_id_ = {'palyer1'}
                    fullscreen={fullscreen}
                    setFullscreen={setFullscreen}
                    refPlayer={refPlayer}
                    refPlayerScreenfull={refPlayerScreenfull}
                    videos_={caseData != undefined && caseData.data != undefined ? caseData.data.caseprimaryvideo_set : []}
                />
                {caseData && caseData.data.first_section_label && caseData.data.first_section_text && (
                <TextBlock
                    heightClass={'heightClass3'}
                    key={caseData.data.first_section_label}
                    text_left={
                        caseData.data.first_section_text
                    }
                    text_right={
                        caseData.data.first_section_label
                    }
                />)}
                {caseData && caseData.data.casesecondaryvideo_set && caseData.data.casesecondaryvideo_set.length && caseData.data.casesecondaryvideo_set.length > 0 && (
                <VideoPersonal
                    video_id_ = {'palyer2'}
                    fullscreen={fullscreen1}
                    setFullscreen={setFullscreen1}
                    refPlayer={refPlayer1}
                    refPlayerScreenfull={refPlayerScreenfull1}
                    videos_={caseData != undefined && caseData.data != undefined ? caseData.data.casesecondaryvideo_set : []}
                />
                )}
                {caseData && caseData.data.secondary_section_label && caseData.data.secondary_section_text && (
                <TextBlock
                    heightClass={'heightClass'}
                    key={caseData.data.secondary_section_label}
                    text_left={
                        caseData.data.secondary_section_text
                    }
                    text_right={
                        caseData.data.secondary_section_label
                    }
                />)}
                {caseData && caseData.data.casesliderimage_set && caseData.data.casesliderimage_set.length && caseData.data.casesliderimage_set.length > 0 && (
                <PersonalSlider photos_slider_={caseData != undefined && caseData.data != undefined && caseData.data.casesliderimage_set != undefined ? caseData.data.casesliderimage_set : []} />
                )}
                {caseData && caseData.data.third_section_label && caseData.data.third_section_text && (
                <TextBlock
                    key={caseData.data.third_section_label}
                    heightClass={'heightClass2'}
                    text_left={
                        caseData.data.third_section_text
                    }
                    text_right={
                        caseData.data.third_section_label
                    }
                />)}

                {caseData && caseData.data.casegalleryimage_set && caseData.data.casegalleryimage_set.length && (
                    <>
                        {caseData.data.casegalleryimage_set.map(i => {
                            return (
                                <div className='PreviewImages'>
                                    <img src={i.image} alt={i.description} />
                                </div>
                            )
                        })}
                    </>
                )}
                {caseData && caseData.data.casemembersrow_set && caseData.data.casemembersrow_set.length && caseData.data.casemembersrow_set.length > 0 && (
                <ContactBlock casemembersrow_set_={caseData != undefined && caseData.data != undefined && caseData.data.casemembersrow_set != undefined ? caseData.data.casemembersrow_set : []} />
                )}
                <div className="next-project" style={{backgroundImage: `url()`,position: 'relative'}}>
                    <span className="shape pos5" />
                    <Link to={'/cases/' + caseData.data.next_case.slug} >
                        <div className="pStoryBtn storyBtn gradientBtn">
                            <p>
                                Explore
                                <br />
                                this project
                            </p>
                        </div>
                    </Link>
                    <div className='backVideoNext'>
                        <video playsInline loop autoPlay muted id='backVideoNext_'>
                            <source src={caseData ? caseData.data.next_case.preview_background : ''} type="video/mp4"/>
                        </video>
                    </div>
                    <div className={'parallaxImageBlock_size parallaxImageBlock_shum'}></div>
                    <div className={'parallaxImageBlock_size parallaxImageBlock_overlay'}></div>
                    <div className="info-block" style={{position: 'relative', zIndex: 2003}}>
                        <article dangerouslySetInnerHTML={{__html: caseData.data.next_case.name_styled}}></article>
                        <p>{caseData.data.next_case.description}</p>
                        <Link to={'/cases/' + caseData.data.next_case.slug} >
                            <p className="next-proj">Next project</p>
                        </Link>
                    </div>
                </div>

                <StoryFooter />
            </div>
        </CursorProvider>
    )
}

export default CasesPagePersonal
