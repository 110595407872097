import React from 'react'
import { Link } from 'react-router-dom'
import StoryFooter from '../Pages/StoryPage/StoryComponents/StoryBottom/StoryFooter'
import {useLogoCategoryData} from "../../api";
import CursorProvider from '../CursorProvider/CursorProvider'

const StoryMobileFull = () => {
    const {isLoading, isSuccess, data} = useLogoCategoryData()
    return (
        <CursorProvider>
        <div className="story-mobile">
            <div className="mobile-header">
                <Link to="/cases" className="link">
                    Cases
                </Link>
                <Link to="/" className="to-home"></Link>
                <Link to="/hire" className="link">
                    Hire us
                </Link>
            </div>
            <Link to="/story" className="backHref">
                &#60; Back to story
            </Link>
            <div className="sftf story-full-text">
                We push the boundaries of visual effects and work with our
                clients to innovate in the real world.
            </div>
            {!isLoading && isSuccess && (
                <div className='marques'>
                    {data.data.results.map((i, idx) => {
                        return (
                            <div>
                            <div key={i.name} className={idx % 2 === 0 ? 'story-bottom-marquee' : 'story-bottom-marquee-reverse'}>
                                <div className={idx % 2 === 0 ? 'marquee-div' : 'marquee-div-reverse'}>
                                    <span>
                                        {i.logo_set.slice(0,(data.data.results.lenght/2)).map(i => {
                                            return (
                                                <div key={`${i.name}-main`} className={idx % 2 === 0 ? 'marquee-img' : 'marquee-img-reverse'} style={{backgroundImage: `url(${i.image})`}} />
                                            )
                                        })}
                                        {i.logo_set.map(i => {
                                            return (
                                                <div key={`${i.name}-secondary`} className={idx % 2 === 0 ? 'marquee-img' : 'marquee-img-reverse'} style={{backgroundImage: `url(${i.image})`}} />
                                            )
                                        })}
                                    </span>
                                    <span>
                                        {i.logo_set.slice(((data.data.results.lenght/2)-1),(data.data.results.lenght/2)).map(i => {
                                            return (
                                                <div key={`${i.name}-main`} className={idx % 2 === 0 ? 'marquee-img' : 'marquee-img-reverse'} style={{backgroundImage: `url(${i.image})`}} />
                                            )
                                        })}
                                        {i.logo_set.map(i => {
                                            return (
                                                <div key={`${i.name}-secondary`} className={idx % 2 === 0 ? 'marquee-img' : 'marquee-img-reverse'} style={{backgroundImage: `url(${i.image})`}} />
                                            )
                                        })}
                                    </span>
                                </div>
                            </div>
                            </div>
                        )
                    })}
                </div>
            )}
            <div className="stftf2 story-full-text ">
                Specialized in high end CG and VFX for Advertising, TV, Print,
                digital, series, short and now long format films since its
                launch, WeareFX has consistently evolved its processes as VFX
                specialists with a strong emphasis on art direction, design and
                technique development.
            </div>
            <div className="story-p-text spt2">
                WeAreFX`s is fully equipped and growing Ukraine-based studio accompanied 
                with a host of talents, including experienced lead
                3D Project Managers, 3D VFX artists, animators, lead lighting
                and 3D renders. Their dedicated compositing teams include
                specialized Flame Artists, Shoot Supervisors and a host of in
                house, and After Effects compositors.
            </div>
            <div className="story-mobile-ellipse">
                <Link to="/cases">
                    <div className="violetMobileMargStoryFull casesBtn gradientBtn violetMobileMarg">
                        <p>
                            View our
                            <br />
                            works
                        </p>
                    </div>
                </Link>
            </div>
            <Link to="/hire">
                <div className="violetBtn">
                    <p>
                        we’re ready
                        <br />
                        hire us!
                    </p>
                </div>
            </Link>

            <div>
                <StoryFooter current_page_={'story'} />
            </div>
        </div>
        </CursorProvider>
    )
}

export default StoryMobileFull
