import React from 'react'
import './StoryBottom.css'
import StoryFooter from './StoryFooter'

const StoryBottom = (props) => {
    const {isLoading, isSuccess, data} = props

    return (
        <div className="story-bottom">
            <div className="story-main-content content-2 content-2-mb">
                <p>
                    We push the boundaries<br />of visual effects and work<br />with
                    our clients to innovate<br />in the real world.
                </p>
            </div>
            {!isLoading && isSuccess && (
                <div className='marques'>
                    {data.data.results.map((i, idx) => {
                        return (
                            <div key={i.name} className={idx % 2 === 0 ? 'story-bottom-marquee' : 'story-bottom-marquee-reverse'}>
                                <div className={idx % 2 === 0 ? 'marquee-div' : 'marquee-div-reverse'}>
                                    <span>
                                        {i.logo_set.slice(0,(data.data.results.lenght/2)).map(i => {
                                            return (
                                                <div key={`${i.name}-main`} className={idx % 2 === 0 ? 'marquee-img' : 'marquee-img-reverse'} style={{backgroundImage: `url(${i.image})`}} />
                                            )
                                        })}
                                        {i.logo_set.map(i => {
                                            return (
                                                <div key={`${i.name}-secondary`} className={idx % 2 === 0 ? 'marquee-img' : 'marquee-img-reverse'} style={{backgroundImage: `url(${i.image})`}} />
                                            )
                                        })}
                                    </span>
                                    <span>
                                        {i.logo_set.slice(((data.data.results.lenght/2)-1),(data.data.results.lenght/2)).map(i => {
                                            return (
                                                <div key={`${i.name}-main`} className={idx % 2 === 0 ? 'marquee-img' : 'marquee-img-reverse'} style={{backgroundImage: `url(${i.image})`}} />
                                            )
                                        })}
                                        {i.logo_set.map(i => {
                                            return (
                                                <div key={`${i.name}-secondary`} className={idx % 2 === 0 ? 'marquee-img' : 'marquee-img-reverse'} style={{backgroundImage: `url(${i.image})`}} />
                                            )
                                        })}
                                    </span>
                                </div>
                            </div>
                        )
                    })}
                </div>
            )}
            <StoryFooter mForFooter={'mForFooter'} current_page_={'story'}/>
        </div>
    )
}

export default StoryBottom
