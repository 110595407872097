import React, { useState, useRef } from 'react'
import Slider from 'react-slick'

export const PersonalSliderMobile = ({photos_slider_}) => {
    const refSlider = useRef(null)

    const nextSlide = () => {
        refSlider.current.slickNext()
    }
    const prevSlide = () => {
        refSlider.current.slickPrev()
    }
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1366,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
            {
                breakpoint: 650,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
            }
        ],
    }
    const slidesArray = photos_slider_;
    const [trackSlider, setTrackSlider] = useState('')

    const onMouseEnter = () => {
        setTrackSlider('sliderTrackMoved')
    }
    const onMouseLeave = () => {
        setTrackSlider('')
    }
    return (
        <div className={`${trackSlider} personalSlider`}>
            <Slider
                ref={refSlider}
                className={`personal-slider`}
                {...settings}
            >
                {slidesArray.map((item,indx) => (
                    <div
                        onMouseEnter={() => onMouseEnter()}
                        onMouseLeave={() => onMouseLeave()}
                        key={indx}
                        className='slideFlex'
                    >
                        <div style={{backgroundImage: `url(${item.image})`}}></div>
                    </div>
                ))}
            </Slider>
            <button
                id="prevBtnSlider"
                className="prev-slider"
                onClick={() => prevSlide()}
            />
            <button
                id="nextBtnSlider"
                className="next-slider"
                onClick={() => nextSlide()}
            />
        </div>
    )
}
