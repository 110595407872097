import React from 'react'
import { Link } from 'react-router-dom'

export const PersonalHeader = (props) => {
    const {name, description} = props;

    return (
        <div>
            <div className="personal-header">
                <div className="personal-left">
                    <div>
                        <Link style={{minHeight: '24px'}} className='personal-left-div hover__special__menu' to="/cases">
                            <h1>our</h1>
                            <h1>{'cases <'}</h1>
                        </Link>
                    </div>
                    <div>
                        <Link className="link hover__special__menu" to="/story">
                            our story
                        </Link>
                        <Link className="link hover__special__menu" to="/hire">
                            hire us
                        </Link>
                    </div>
                </div>
                <Link to="/">
                        <div className="animated-logo-X">X</div>
                    <div className="animated-logo personal-right"></div>
                </Link>
            </div>
            {name && <div className="header-title" dangerouslySetInnerHTML={{__html: name}}></div>}
            {description && <h6 className="header-desc">{description}</h6>}
        </div>
    )
}
